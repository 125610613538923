//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import formMixin from '~/mixins/formMixin'
import formConfig from '~/config/forms.json'
import countriesEn from '~/data/countries_en.json'
import countriesSr from '~/data/countries_sr.json'
import regionsEn from '~/data/regions_en.json'
import citiesEn from '~/data/cities_en.json'
// import FormGroup from '~/components/form/FormGroup'

export default {
  name: 'RelationForm',
  mixins: [
    formMixin
  ],
  props: {
    entityType: {
      type: String,
      required: true
    },
    model: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      default: 'create'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // debug: true,
      redirectAfterSuccess: false,
      formConfig: formConfig[this.entityType]
    }
  },
  computed: {
    locale () {
      return this.$store.getters.locale
    },
    countries () {
      return countriesEn
    },
    countryOptions () {
      return this.countries.map(c => ({ value: c.code, text: c.name }))
    },
    regions () {
      return regionsEn
    },
    regionOptions () {
      return this.regions.map(r => ({value: r, text: r}))
    },
    cities () {
      return citiesEn
    },
    cityOptions () {
      return this.cities.map(r => ({value: r, text: r}))
    },
    formFields () {
      const fields = []
      for (const [code, fieldConfig] of Object.entries(this.formConfig.fields)) {
        const field = {
          name: code,
          validation: fieldConfig.fe_validation,
          label: this.$t(`${this.entityType}.form.${code}.label`),
          helpText: this.$te(`${this.entityType}.form.${code}.help`)
            ? this.$t(`${this.entityType}.form.${code}.help`)
            : '',
          options: {
            // disabled: !this.statusOpen,
            type: fieldConfig.input_type,
            readonly: this.readonly,
            disabled: this.disabled,
            placeholder:
              this.$te(`${this.entityType}.form.${code}.placeholder`) ?
                this.$t(`${this.entityType}.form.${code}.placeholder`) :
                '',
            required: fieldConfig.required,
            multiple: [
              'asset_collection',
              'relation_collection'
            ].includes(fieldConfig.type),
            options: this.getFieldOptions(fieldConfig),
            class: 'col-md-12'
          }
        }
        fields.push(field)
      }
      return fields
    }
  },
  created () {
    if (this.mode === 'edit' && this.model) {
      this.filledFormData = this.model.fields
    }
  },
  methods: {
    async $_sendRequest (url, data) {
      // Options
      // @TODO create a configurable request options.
      let result
      try {
        await this.$axios.get('sanctum/csrf-cookie')
        if (this.mode === 'create') {
          result = await this.$axios.post(
            `/api/data/subforms/${this.entityType}`,
            data
          )
        } else {
          result = await this.$axios.put(
            `/api/data/subforms/${this.entityType}/${this.model.id}`,
            data
          )
        }
        this.$emit('onSave', result)
      } catch (e) {
        if (!e.response) {
          return
        }

        if (e.response.status === 422) {
          // handle validation errors
          const serverData = e.response.data
          if (serverData.errors) {
            this.$refs.observer.setErrors(serverData.errors)
          }
          this.showError(this.$t('form.validationErrorMessage'), this.$t('form.errorTitle'), 'danger')
        }
        if (e.response.code === 500) {
          // handel server error
          this.showServerError(this.$t('form.errorTitle'))
        }
        throw e
      }

      return result
    },
    cancel () {
      this.$emit('onCancel')
    },
    getFieldOptions (formConfig) {
      if (!('options' in formConfig)) {
        return null
      }
      const options = formConfig.options

      if (typeof options === 'string' || options instanceof String) {
        return this[options]
      }

      return options.map((o) => {
        const option = {
          value: o.value
        }
        if ('text' in o) {
          option.text = o.text
        } else if ('tKey' in o) {
          option.text = this.$t(o.tKey)
        } else {
          option.text = o.value
        }
        return option
      })
    }
  }
}
