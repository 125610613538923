//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
// components
import AppHeader from '@/components/app/AppHeader'
import AppMainMenu from '@/components/app/AppMainMenu'
import AppFooter from '@/components/app/AppFooter'
import AppLocker from '@/components/app/AppLocker'
import CustomTransition from '@/components/transitions/CustomTransition'

import LogoLoader from '@/components/loaders/LogoLoader'

export default {
  components: {
    AppHeader,
    AppMainMenu,
    AppFooter,
    AppLocker,
    LogoLoader,
    CustomTransition
  },
  data: () => ({
    debug: false,
    appLoaded: false
  }),
  computed: {
    ...mapState([
      'isMainMenuOpen'
    ]),
    locale () {
      return this.$store.getters.locale
    },
    showLockScreen () {
      return this.$config.showLockScreen
    }
  },
  watch: {
    $route(to, from) {
      this.$setLocalePreference(this.$i18n.locale);
    },
  },
  mounted () {
    this.appLoaded = true
  }
}
