//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DateInput',

  props: {
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    labels: {
      'sr-Latn-RS': {
        labelPrevDecade: 'Prethodna decenija',
        labelPrevYear: 'Prethodna godina',
        labelPrevMonth: 'prethodni mesec',
        labelCurrentMonth: 'Trenutni mesec',
        labelNextMonth: 'Sledeći mesec',
        labelNextYear: 'Sledeća godina',
        labelNextDecade: 'Sledeća decenija',
        labelToday: 'Danas',
        labelSelected: 'Izabrani datum',
        labelNoDateSelected: 'Nije selektovan datum',
        labelCalendar: 'Kalendar',
        labelNav: 'Navigacija',
        labelHelp: 'Možete koristiti i strelice na tastaturi'
      }
    }
  }),

  computed: {
    locale () {
      return this.$store.state.app.locale
    },
    dateLocale () {
      switch (this.locale) {
        case 'sr':
          return 'sr-Latn-RS'
        case 'en':
        default:
          return 'en-US'
      }
    },
    firstDatOfWeek () {
      switch (this.locale) {
        case 'en':
          return 0
        default:
          return 1
      }
    }
  },

  watch: {
    // value (val) {
    //   this.$nextTick(() => {
    //     this.$refs.in.value = val
    //   })
    // }
  },

  methods: {
    updateValue ($event) {
      this.$emit('input', $event)
    }
  }
}
